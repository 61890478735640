.empty-input > .input-element-wrapper {
  border-bottom: 1px solid black !important;
  padding-bottom: 2px !important;
}

.success-upload > .input-element-wrapper {
  border-bottom: 2px solid #6e19f0 !important;
  padding-bottom: 1px !important;
}

.input-message {
  margin-right: 16px;
  margin-top: 8px;
  font-size: 14px;
}

.load-success {
  color: #6e19f0 !important;
}

.need-fixes > .input-element-wrapper {
  background-color: #f6da87 !important;

  &:not(.disabled):not(.danger):hover:not(.focused):not(:has(>.success-upload)) {
    background-color: #f6da87 !important;
  }
}

.fix-color {
  color: orange;
}

.red-color {
  color: red;
}
