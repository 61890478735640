// общие стили для инпутов
%input {
  outline: none;
  color: $black-01;
  border: 1px solid $black-05;
  background-color: $white;
  padding: 5px 4px 7px 12px;
  border-radius: $border-radius;
  display: flex;
  justify-content: space-between;
  height: 32px;
  font-size: 14px;
}

%input-wrapper {
  color: $black-01;
  align-items: center;
  border: 1px solid $black-05;
  background-color: $white;
  border-radius: $border-radius;
  display: flex;
  justify-content: space-between;
  height: 32px;

  & > input[class*="input"] {
    background-color: transparent;
    border: none;
    outline: none;
    width: 100%;
    height: 30px;
    border-radius: $border-radius;
    font-family: inherit;
    font-size: 14px;
    padding: 0 4px 0 8px;
    min-width: 0;

    &::placeholder {
      @extend %input-placeholder;
      border-bottom: red;

    }
    &:disabled {
      cursor: not-allowed;
    }
  }
}

%input-hovered {
  border-bottom: 2px solid black !important;
  padding-bottom: 1px !important;
  background-color: rgba(0, 0, 0, 0.04) !important;
}

%input-focused {
  border-bottom: 2px solid #6e19f0 !important;
  padding-bottom: 1px;
  background-color: rgba(0, 0, 0, 0.04);
}

%input-disabled {
  border-color: $black-05;
  background-color: $black-07 !important;
  color: $black-03 !important;
  cursor: not-allowed !important;
}

%input-placeholder {
  color: $black-03;
  border-bottom: red;
}

%input-prefix {
  margin-left: 8px;
}

%input-suffix {
  margin-right: 14px;
}

%input-prefix, %input-suffix {
  user-select: none;
  flex: none;
  white-space: nowrap;
  color: $black-03;
}

%input-icon-search {
  &:before {
    content: '\e92c';
    color: $black-03;
    width: 20px;
    height: 20px;
    display: block;
  }
}

%input-clear-icon {
  font-size: 18px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  color: $black-03;

  &::before {
    font-family: "Font Awesome 5 Free";
    content: "\00D7";
    position: absolute;
    top: 45%;
    right: 2px;
    transform: translate(-50%, -50%);
    font-style: normal;
  }
}

.input {
  &-wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
  }

  &-element::-ms-clear {
    display: none;
  }

  &-element-wrapper {
    @extend %input-wrapper;
    padding: 0;
    position: relative;
    display: flex;
    flex-direction: row;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 2px solid black;
    border-radius: 0;
    background-color: rgba(0, 0, 0, 0.04);
    outline: none;
    padding-bottom: 1px;
    width: 100%;
    height: 56px;

    &:placeholder-shown {
      border-bottom: 1px solid black !important;
      padding-bottom: 2px;
      background-color: rgba(0, 0, 0, 0.04);
    }

    &:not(.disabled):not(.danger):hover:not(.focused):not(:has(>.success-upload)) {
      @extend %input-hovered;
   }

    &:not(.disabled):not(.danger):not(.hover):has([value=""]) {
      border-bottom: 1px solid black;
      padding-bottom: 2px;
      background-color: rgba(0, 0, 0, 0.04);
    }

    &:not(.disabled):not(.danger):not(.hover) {
      border-bottom: 2px solid black;
      padding-bottom: 1px;
      background-color: rgba(0, 0, 0, 0.04);
    }

    &:not(.focused):not(.disabled).danger {
      border-bottom: 2px solid #e6175c !important;
    }

    &.focused {
      @extend %input-focused;
    }

    &.disabled {
      @extend %input-disabled;
    }
  }

  &-clear-icon {
    @extend %input-clear-icon;
  }

  &-icon {
    margin: 0;
    font-size: 14px;
    color: $black-03;

    &:before {
      position: relative;
      top: 5px;
    }

    &.icon-left {
      padding-left: 5px;

      & + input {
        padding-left: 7px;
      }
    }

    &.icon-right {
      padding-right: 7px;
    }
  }

  &-prefix {
    @extend %input-prefix;
  }

  &-suffix {
    @extend %input-suffix;
  }
}

input[class*="input-element"]:placeholder-shown .input-element-wrapper {
  @extend %input-hovered;
}
