@import '../colors';

.content-header {
  margin-top: 12px;

  .breadcrumbs-top {
    .breadcrumb-wrapper {
      .breadcrumb {
        li {
          cursor: pointer;
          margin-right: 5px;

          &:last-child {
            cursor: auto;
          }

          &:first-child {
            margin-right: 0;
            padding-right: 5px;
            font-size: 0;

            &:after {
              content: '';
              font-size: 20px;
              display: block;
              width: 15px;
              height: 15px;
              background: url('../../../images/icons/svg/home.svg');
              background-size: 15px 15px;
              margin: 3px 5px 0 0;
            }
          }

          &:empty {
            display: none;
          }
        }
      }
    }
  }

  .breadcrumb-right {
    display: none;
  }
}
