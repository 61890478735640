.calendar {
  &-wrapper {
    direction: ltr !important;

    visibility: hidden;
    width: 204px;
    position: absolute;
    margin-top: 4px;
    top: 100%;
    z-index: 2;
    background-color: $white;
    box-shadow: 0 .2rem .7rem 0 rgba($black-01, .25);
    border-radius: $input-border-radius;
    left: 0;
    &.wide-list {
      .suggestion-wrapper {
        width: 200px;
      }
    }

    &.visible {
      visibility: visible;
    }

    &.pos-top {
      top: auto;
      bottom: 100%;
      box-shadow: $shadow-level-2-top;
    }

    &.pos-right {
      right: 0;
    }
  }

  &-nav {
    display: flex;
    justify-content: space-between;
    padding: 8px;

    %common-icon {
      font-family: "Font Awesome 6 Free";
      font-weight: 400;
      font-style: normal;
      font-size: 24px;
    }

    %common-button {
      display: flex;
      justify-content: center;
      align-items: baseline;
      cursor: pointer;

      &.disabled-button {
        cursor: default;

        &:hover {
          background-color: $white;
        }

        .calendar-prev-icon {
          color: $black-07;
        }

        .calendar-next-icon {
          color: $black-07;
        }
      }
    }

    .calendar {
      &-prev-button {
        @extend %common-button;
      }

      &-next-button {
        @extend %common-button;
      }

      &-next-icon {
        @extend %common-icon;
        color: $black-04;

        &:before {
          content: "\f104";
          font-family: "Font Awesome 6 Free";
          font-weight: 900;
        }

        &:hover {
          color: $black-03;
        }
      }

      &-prev-icon {
        @extend %common-icon;
        color: $black-04;

        &:before {
          content: "\f105";
          font-family: "Font Awesome 6 Free";
          font-weight: 900;
        }

        &:hover {
          color: $black-03;
        }
      }

      &-title {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        flex-grow: 1;

        &:hover {
          background-color: $white;
        }

        &.disabled-title {
          cursor: default;
          color: inherit;

          &:hover {
            background-color: $white;
          }
        }
      }
    }
  }

  &-week-days {
    display: flex;
    justify-content: space-around;
    background-color: $white;
    height: 24px;
    align-items: center;
    padding: 0 8px;

    .calendar-date-cell {
      padding: 5px;
      color: $black-03;
      cursor: default;
      height: 32px;
      width: 32px;
      font-size: 12px;
      text-align: center;
    }
  }

  &-month-dates {
    display: flex;
    flex-direction: column;
    padding: 8px;

    .calendar {
      &-dates-row {
        display: flex;
        justify-content: space-around;
      }

      &-date-cell {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        height: 24px;
        width: 24px;
        font-size: 14px;
        border-radius: 50%;
        margin: 1px;

        &.different-month-date {
          color: $black-05;
          &:hover {
            background-color: $black-06;
            color: $black-01;
            &.marked-date::after {
              background-color: $black-04;
            }
          }

          &.marked-date::after {
            background-color: $black-05;
          }
        }

        &.today {
          color: $black-01;
          border: none;
          font-weight: 600;

          &.selected {
            color: $black-01;
          }
        }

        &:hover {
          background-color: $black-07;
        }

        &.active {
          border: none;
          background-color: $black-06;
        }

        &.selected {
          color: $black-01;
          background-color: $black-06;
          border: none;
        }

        &.disabled-date {
          cursor: not-allowed;
          color: $black-05;

          &:hover {
            background-color: $white;
          }

          &.marked-date::after {
            background-color: $black-05;
          }
        }

        &.marked-date::after {
          content: '';
          width: 4px;
          height: 4px;
          border-radius: 50%;
          background-color: $black-04;
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
  }

  &-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    cursor: pointer;
    color: $black-05;
  }

  &-month-year {
    &-view {
      padding: 0 8px 12px 8px;
    }
    &-row {
      display: flex;
      justify-content: space-around;
    }

    &-cell {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 56px;
      height: 36px;
      cursor: pointer;
      border-radius: 8px;
      margin: 2px;

      &:hover {
        background-color: $black-07;
      }

      &.active {
        border: none;
        background-color: $black-06;
      }

      &.disabled-month-year {
        cursor: not-allowed;
        color: $black-04;

        &:hover {
          background-color: $white;
        }
      }

      &.different-year {
        &:hover {
          background-color: $black-06;
          color: $black-01;
        }
      }
    }
  }

  &-prev-button {
    .calendar-prev-icon {
      width: 1em;
      height: 1em;
      font-size: 18px;
      color: $black-03;

      &:before {
        content: "\f104";
        font-family: "Font Awesome 5 Free";
        font-weight: 900;

      }
    }
  }

  &-next-button {
    .calendar-next-icon {
      width: 1em;
      height: 1em;
      font-size: 18px;
      color: $black-03;

      &:before {
        content: "\f105";
        font-family: "Font Awesome 5 Free";
        font-weight: 900;

      }
    }
  }
}
