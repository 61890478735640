.datepicker {
  &-wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
  }

  &-input-wrapper {
    @extend %input-wrapper;
    padding: 0;
    position: relative;
    display: flex;
    flex-direction: row;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 2px solid black;
    border-radius: 0;
    background-color: rgba(0, 0, 0, 0.04);
    outline: none;
    padding-bottom: 1px;
    width: 100%;
    height: 56px;

    &:hover:not(.disabled-state) {
      @extend %input-hovered;
    }

    &.focused {
      @extend %input-focused;
    }

    &.danger:not(.disabled-state):not(.focused) {
      border-color: $brand-danger;

      &:hover {
        border-color: $brand-danger;
      }
    }

    &:not(.disabled):not(.danger):not(.hover):has([value=""]) {
      border-bottom: 1px solid black;
      padding-bottom: 2px;
      background-color: rgba(0, 0, 0, 0.04);
    }

    &.disabled-state {
      @extend %input-disabled;

      & > .datepicker-input {
        background-color: inherit;
      }

      .datepicker-calendar-icon {
        cursor: default;
      }
    }
  }

  &-icons-wrapper {
    display: inline-flex;
    height: 30px;
    align-items: center;
    margin: 16px;

    & > .datepicker-calendar-icon {
      width: 30px;
      height: 29px;
      padding: 4px;
      cursor: pointer;
      display: inline-flex;
      justify-content: center;
      align-items: center;

      &::before {
        content: "\f073";
        font-family: "Font Awesome 5 Free";
        font-size: 20px;
        font-weight: 400;
        color: black;
        display: block;
        width: 14px;
        height: 20px;
      }
    }

    & > span {
      &:last-child {
        border-top-right-radius:  6px;
        border-bottom-right-radius: 6px;
      }

      &:first-child {
        border-top-left-radius:  6px;
        border-bottom-left-radius: 6px;
      }
    }
  }
}
