/*================================================================================
	Item Name: Compie - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */
@import './icons.css';
@import './scrollbar.scss';
@import '../../common/perach-ui/scss/index.scss';
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600');

.tooltip-arrow::before {
  border-top-color: #00ff96 !important;
  border-width: 24px 16px 0 !important;
  top: -12px !important;
  left: -12px;
}

.tooltip-inner {
  max-width: 336px;
}

.form-check:not(.form-switch) {
  .form-check-input[type='checkbox'] {
    &:checked {
      background-size: 65%;
    }
  }

  .form-check-input {
    &:disabled:not(:checked) {
      background-color: blue;
      border-color: darkred;
      opacity: 1;
    }
  }
}

.form-check-input {
  &:not(:disabled) {
    &:checked {
      box-shadow: none;
    }

    &:indeterminate {
      background-size: 85%;
    }
  }
}

/* VARS */
$open-sans: 'Open Sans', 'Helvetica', sans-serif;

$darkNavy: #213140;
$teal1: #66B3FB;
$teal2: #4B9DEA;
$charcoal: #555555;
$gold: #B6985A;

$activeShadow: 0 0 10px rgba($teal1, .5);

/* MIXINS */
@mixin focusOutline {
  outline: dotted 1px #CCC;
  outline-offset: .45rem;
}

@mixin hideInput {
  width: 0;
  height: 0;
  position: absolute;
  left: -9999px;
}

@mixin breakpoint($point) {
  @if $point == 1100 {
    @media (max-width: 1100px) {
      @content ;
    }
  } @else if $point == 800 {
    @media (max-width: 800px) {
      @content ;
    }
  }
}


fieldset {
  //margin: 0; padding: 2rem;
  //box-sizing: border-box; display: block;
  //border: none; border: solid 1px #CCC;
  //min-width: 0;
  //background-color: #FFF;
  legend {
    margin: 0 0 1.5rem;
    padding: 0;
    width: 100%;
    float: left;
    display: table;
    font-size: 1.5rem;
    line-height: 140%;
    font-weight: 600;
    color: #333;

    + * {
      clear: both;
    }
  }
}

body:not(:-moz-handler-blocked) fieldset {
  display: table-cell;
}

/* TOGGLE STYLING */
.toggle {
  margin: 0 0 1.5rem;
  box-sizing: border-box;
  font-size: 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: stretch;

  input {
    @include hideInput;
  }

  input + label {
    margin: 0;
    padding: .75rem 2rem;
    box-sizing: border-box;
    position: relative;
    display: inline-block;
    border: solid 1px black;
    background-color: #FFF;
    font-size: 1rem;
    line-height: 140%;
    font-weight: 600;
    text-align: center;
    box-shadow: 0 0 0 rgba(255, 255, 255, 0);
    transition: border-color .15s ease-out,
    color .25s ease-out,
    background-color .15s ease-out,
    box-shadow .15s ease-out;

    /* ADD THESE PROPERTIES TO SWITCH FROM AUTO WIDTH TO FULL WIDTH */
    /*flex: 0 0 50%; display: flex; justify-content: center; align-items: center;*/
    /* ----- */

    &:first-of-type {
      border-radius: 0;
      border-right: none;
    }

    &:last-of-type {
      border-radius: 0;
      border-left: none;
    }
  }

  input:hover + label {
    border-color: black;
  }

  input:checked + label {
    background-color: rgba(0, 255, 150, 0.12);
    color: #000000;
    box-shadow: unset;
    border-color: black;
    z-index: 1;
  }

  input:focus + label {
    @include focusOutline;
  }

  @include breakpoint(800) {
    input + label {
      padding: .75rem .25rem;
      flex: 1 1 auto;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

/* STYLING FOR THE STATUS HELPER TEXT FOR THE DEMO */
.status {
  margin: 0;
  font-size: 1rem;
  font-weight: 400;

  span {
    font-weight: 600;
    color: $gold;

    &:first-of-type {
      display: inline;
    }

    &:last-of-type {
      display: none;
    }
  }

  @include breakpoint(800) {
    span {
      &:first-of-type {
        display: none;
      }

      &:last-of-type {
        display: inline;
      }
    }
  }
}


/** Checkbox */
.checkboxes {
  margin-top: 2rem;
}

@keyframes wave {
  50% {
    transform: scale(0.9);
  }
}

.page-title {
  font-family: Assistant, bold;
  //width: 288px;
  height: 73px;
  margin: auto;
  font-size: 56px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
  text-align: center;
  color: #000;
  white-space: nowrap;
}

@media screen and (max-width: 600px) {
  .page-title {
    font-family: Assistant, bold;
    margin: auto;
    font-size: 40px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: normal;
    text-align: center;
    color: #000;
    white-space: normal;
  }
}

.page-subtitle {
  font-family: Assistant;
  max-width: 704px;
  margin: 24px 20px 40px 20px;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  text-align: center;
  color: #000;
}

.title-wrapper {
  display: flex;
  flex-direction: column;
  width: 708px;
  margin: 160px auto 0;

  &.eligibility-centered {

    justify-content: center;
    align-items: center;
    width: auto;
    margin: auto;
    flex: 1;
    @media screen and (max-width: 600px) {
      //justify-content: flex-start;
      margin: 100px 25px;
      //flex: 0;
      //height: auto;
    }
  }
}

.eligibility-opt-container {
  .otp-wrapper {
    @media screen and (max-width: 600px) {
      width: auto !important;
    }
  }
}

@media screen and (max-width: 600px) {
  .title-wrapper {
    display: flex;
    flex-direction: column;
    width: auto;
    margin: 160px auto 0;
  }
}

.width-100 {
  width: 100%;
  white-space: nowrap;
}

.label-block-right {
  display: block;
  textAlign: right;
}

// Input Customisation
//input[class*="input-element"] {
//  border-top: none;
//  border-left: none;
//  border-right: none;
//  background-color: rgba(0, 0, 0, 0.04);
//  border-bottom: 2px solid black;
//  border-radius: 0;
//  outline: none;
//  padding-bottom: 1px;
//  width: 100%;
//  height: 56px;
//
//  &:focus {
//    border-bottom: 2px solid #6e19f0 !important;
//    padding-bottom: 1px;
//    background-color: rgba(0, 0, 0, 0.04);
//  }
//
//  &:hover:not(:focus) {
//    border-bottom: 2px solid black;
//    padding-bottom: 1px;
//    background-color: rgba(0, 0, 0, 0.04);
//  }
//
//  &:placeholder-shown {
//    border-bottom: 1px solid black;
//    padding-bottom: 2px;
//    background-color: rgba(0, 0, 0, 0.04);
//  }
//}

.dropdownselect-input-wrapper {
  border-top: none;
  border-left: none;
  border-right: none;
  background-color: rgba(0, 0, 0, 0.04);
  border-bottom: 2px solid black;
  border-radius: 0;
  outline: none;
  padding-bottom: 1px;
  width: 100%;
  height: 56px;

  &:focus {
    border-bottom: 2px solid #6e19f0 !important;
    padding-bottom: 1px;
    background-color: rgba(0, 0, 0, 0.04);
  }

  &:hover:not(:focus) {
    border-bottom: 2px solid black;
    padding-bottom: 1px;
    background-color: rgba(0, 0, 0, 0.04);
  }

  &:placeholder-shown {
    border-bottom: 1px solid black;
    padding-bottom: 2px;
    background-color: rgba(0, 0, 0, 0.04);
  }
}


//.danger > input {
//  border-bottom: 2px solid #e6175c !important;
//}

.otp-wrapper {
  width: 360px !important;
  margin: auto;
}

.single-link {
  font-family: Assistant;
  margin: 38px 0 40px 0;
  text-align: center;
  color: #6e19f0;
  font-size: 16px;
  font-weight: 600;
}

.label-text {
  font-size: 16px;
  font-weight: 600;
}

.check {
  width: 32px;
  height: 32px;
  font-family: FontAwesome6Pro;
  font-size: 32px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.75;
  letter-spacing: normal;
  text-align: center;
  color: #000;
}

.circle-container {
  width: 80px;
  height: 80px;
  margin: 0 auto 16px auto;
  padding: 24.4px 23.6px 23.6px 24.4px;
  border-radius: 40px;
  background-color: #00ff96;
}

.form-container {
  //max-width: 708px;
  //width: auto;
  //margin: 0 auto;
}

.reset-password-inputs-container {
  display: flex;
  width: 100%;
  flex-wrap: wrap;

  & > * {
    min-width: 100%;
    margin-bottom: 8px;
  }
}

.label-perach {
  font-family: Assistant;
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  //text-align: left;
  color: #000;
  width: 100%;
}

.invalid-message-item {
  margin-top: 8px;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: right;
  color: #e6175c;
}

.checkbox-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.label-more {
  display: flex;
  justify-content: space-between;
}

.otp-input-field {
  border-top: none;
  border-left: none;
  border-right: none;
  background-color: rgba(0, 0, 0, 0.04);
  border-bottom: 2px solid black;
  border-radius: 0;
  outline: none;
  padding-bottom: 1px;
  height: 56px;
  width: 52px;

  &:focus {
    border-bottom: 2px solid #6e19f0 !important;
    padding-bottom: 1px !important;
  }

  &:hover {
    border-bottom: 2px solid black;
    padding-bottom: 1px;
  }

  &:not(.disabled):not(.danger):not(.hover):has([value=""]) {
    border-bottom: 1px solid black;
    padding-bottom: 2px;
    background-color: rgba(0, 0, 0, 0.04);
  }

  &:placeholder-shown:not(:focus) {
    border-bottom: 1px solid black !important;
    padding-bottom: 2px;
    background-color: rgba(0, 0, 0, 0.04);
  }
}

.rr {
  flex-direction: row-reverse;
}

.label-danger {
  color: #e6175c;
}

.label-success {
  color: #6e19f0;
}

// scss-docs-start tooltip-variables
//$tooltip-font-size:                 $font-size-sm !default;
//$tooltip-max-width:                 200px !default;
//$tooltip-color:                     $white !default;
//$tooltip-bg:                        $black !default;
//$tooltip-border-radius:             $border-radius !default;
//$tooltip-opacity:                   .9 !default;
//$tooltip-padding-y:                 $spacer * .25 !default;
//$tooltip-padding-x:                 $spacer * .5 !default;
//$tooltip-margin:                    0 !default;
//
//$tooltip-arrow-width:               .8rem !default;
//$tooltip-arrow-height:              .4rem !default;
$tooltip-arrow-color: #00ff96 !default;


.error-box {
  //max-width: 708px;
  height: 64px;
  margin: 16px auto 80px auto;
  padding: 0 24px 0 24px;
  border-radius: 15px;
  border: solid 2px #000;
  background-color: rgba(230, 23, 92, 0.12);
  display: flex;
  align-items: center;
  font-family: Assistant;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: right !important;
  color: #000;
}

.form-info-box {
  height: 64px;
  margin: 16px auto 40px auto;
  padding: 0 24px 0 24px;
  border-radius: 15px;
  border: solid 2px #000;
  background-color: rgba(117, 251, 160, 0.12);
  display: flex;
  align-items: center;
  font-family: Assistant;
  color: #000;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: right !important;
}

.form-divider-container {
  font-family: Assistant;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000;
  text-align: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.24);
  line-height: 0.1em;
  padding-right: 24px;
  margin-bottom: 40px;
}

.form-divider-container > span {
  background-color: #FFFFFF;
  padding: 12px;
}


.eligibility-fail-container {
  width: 343px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.info-text {
  font-family: Assistant;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: right;
  color: #000;
}

.thik-buton-text {
  font-family: Assistant;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

.main-layout {
  height: 100%;
  display: flex;
  flex-direction: column;
  //justify-content: space-between;
}

.header-container {
  padding: 0 7%;
  height: 96px;
  background-color: #fff;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-around;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1049;
  @media screen and (max-width: 600px) {
    margin: 0;
  }
}

.header-nav {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  &.last {
    width: 100%;
    flex: 1;
    margin-right: 40px;
  }
}

.header-nav-element {
  padding: 35px 32px;
  font-family: Assistant;
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  color: #000;
}

.header-logo {
  max-width: 206px;
}

.header-dropdown {
  max-width: 152px;
  padding: 38px 24px 37px;
  background-color: #6e19f0;
  margin: 0 0 0 12px;
  font-family: Assistant;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

.header-dropdown-element {
  padding: 18px 16px 17px;
  font-family: Assistant;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: right;
  color: #000;
  justify-content: space-between;
  display: flex;
  align-items: center;
  width: 100% !important;
}

.footer-container {
  margin-top: auto;
  border-top: 1px solid rgba(#000, 0.24);
  //height: 216px;
  //min-height: 216px;
  height: 100px;
  min-height: 100px;
  margin: auto 0 0;
  background-color: #f7f7f7;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-around;
  align-items: center;
  @media screen and (max-width: 600px) {
    height: auto;
  }

  .perah-logo-container {
    .perach-usefull-links {
      margin-top: 10px;
    }
  }
}

.social-network-logo {
  height: 48px;
  width: 48px;
  border-radius: 50%;
  background-color: #6e19f0;
  color: #FFFFFF;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  display: table;
}

.center-icon {
  display: table-cell;
  vertical-align: middle;
  text-align: center;

  & > a {
    color: white;
  }
}

.hamburger-menu-button {
  display: none;
}

.user-menu-button {
  display: none;
}

@media screen and (max-width: 600px) {
  .hamburger-menu-button {
    height: 48px;
    width: 48px;
    border-radius: 50%;
    border: 1px solid black;
    display: table;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: center;
    color: #000;
  }
  .header-nav {
    display: none;
  }
  .user-menu-button {
    display: table;
    height: 48px;
    width: 48px;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: center;
    color: #000;
  }

  .header-container {
    //padding-top: 40px;
    //height: 90px !important;
    background-color: #fff;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-around;
    align-items: center;
  }

  .header-logo {
    width: 176px;
  }
  .responsive-header {
    display: flex;
    flex-direction: column;
  }
  .responsive-navbar {
    padding-top: 8px;
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 90px;
    right: 0;
    left: 0;
    z-index: 1049;
    background-color: #FFFFFF;
    align-items: flex-start;
  }
  .responsive-header-element {
    padding-right: 24px;
    margin-top: auto;
    margin-bottom: auto;
    height: 64px;
    font-family: Assistant;
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    text-align: right;
    color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .responsive-header-buttons {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    align-content: flex-start;
  }
  .responsive-header-button {
    margin: 24px 24px 8px 24px;
  }
}

.footer-nav {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.footer-social-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.footer-nav-element {
  padding: 35px 32px;
  font-family: Assistant;
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  color: #000;
}

.footer-logo-desktop {
  max-width: 206px;
}

.footer-logo-mobile {
  display: none;
}

@media screen and (max-width: 600px) {
  .footer-container {
    padding-top: 40px;
    border-top: 1px solid rgba(#000, 0.24);

    margin-top: auto;
    background-color: #f7f7f7;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    min-height: auto;
  }
  .footer-nav-element {
    height: 48px;
    font-family: Assistant;
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: center;
    color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .footer-nav {
    display: none;
    //display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
  }
  .footer-logo-desktop {
    display: none;
  }
  .footer-logo-mobile {
    display: block;
    width: 206px;
    float: right;
    margin-left: -34px;
  }
  .footer-mobile {
    display: inline-flex;
    margin: 0 10px 25px 10px;

    .perach-logo-container {
      display: none;
    }
  }
}

.admin-wrapper {
  display: flex;
  flex-direction: column;
  width: 708px;
  margin: 0 auto 0;
  justify-content: flex-start;
  flex-wrap: nowrap;
}

.strong-text-important {
  font-size: 16px !important;
  font-weight: 600 !important;
}
