.vertical-layout.vertical-menu-modern.menu-collapsed
  .main-menu.expanded
  .navbar-header
  .brand-text {
  display: none;
}

.vertical-layout.vertical-menu-modern.menu-collapsed
  .main-menu
  .modern-nav-toggle {
  display: block;
}

.app-content.content.overflow-hidden {
  @media (max-width: 1200px) {
    padding-bottom: 40px;
  }
}

