//  ================================================================================================
//  ? TIP:  It is recommended to use this file for overriding bootstrap variables.
//  ================================================================================================

$primary: #0282ff;
$theme-dark-text-muted-color: #000;
$font-family-monospace: 'Assistant', Helvetica, Arial, serif !default;
$font-family-sans-serif: 'Assistant', Helvetica, Arial, serif !default;
$body-bg: #ffffff;
$body-direction: rtl;
