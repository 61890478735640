.collapse-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.24);
  margin-bottom: 24px;
  font-size: 20px;
  cursor: pointer;
}

.collapse-item-name {
  font-family: Assistant;
  // font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  text-align: right;
  color: #000;
  order: 0;
  flex: 0 1 auto;
  align-self: auto;
  margin: 24px;
}

.collapse-item-sign {
  // font-size: 20px;
  font-weight: normal;
  color: #000;
  order: 0;
  flex: 0 1 auto;
  align-self: auto;
  margin: 24px;
}

@media screen and (max-width: 600px) {
  .collapse-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.24);
    margin-bottom: 24px;
    font-size: 16px;
  }
}
