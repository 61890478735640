.stepper-container {
  display: flex;
  padding: 24px 0 ;
  align-items: flex-start;
  flex-direction: row;
}

.step-container {
  flex: 1;
  position: relative;
  padding-left: 8px;
  padding-right: 8px;
}

.step-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.step-button {
  width: 56px;
  border: none;
  cursor: pointer;
  height: 56px;
  margin: 0px;
  display: flex;
  padding: 0px;
  font-size: 20px;
  align-items: center;
  border-radius: 50%;
  justify-content: center;
  background-color: rgb(224, 224, 224);
}

.step-connector-container {
  top: calc(35px);
  left: calc((-50% + 50px) - 8px);
  right: calc((50% + 50px) - 8px);
  position: absolute;
}

.step-connector {
  display: block;
  border-color: rgb(189, 189, 189);
  border-top-style: solid;
  border-top-width: 2px;
}

@media screen and (max-width: 600px) {
  .step-connector {
    border: none;
  }
}

.step-outer-circle {
  width: 72px;
  height: 72px;
  padding: 8px;
  border-radius: 50%;
  align-items: center;
  display: flex;
  justify-content: center;
}

.step-bg-active {
  background-color: #00ff96;
}

.step-border-active {
  border: solid 1px rgba(0, 0, 0, 0.12);
}

.step-border-done {
  border: solid 1px #000;
}

.step-label {
  width: 40px;
  height: 38px;
  margin: 8px 0 0;
  font-family: Assistant;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: #000;
}

.step-button-content {
  color: #000;
}
