.nav {
  justify-content: center;
}

.page-title {
  margin: 0;
  padding-top: 80px;
  height: auto;
  @media screen   and (max-width: 600px){
    padding-top: 100px;
  }
  &.eligibility{
    white-space: break-spaces;
    padding-top: 0;
  }
}

.nav-tabs .nav-link {
  font-size: 16px;
  color: black;
  font-weight: 600;
  padding: 18px 32px;
  transform: translateY(2px);

}

.nav-tabs .nav-link.active {
  color: black;

  :after {
    background: red;
  }
}

.nav-tabs {
  width: 100%;
  border-bottom: 2px solid rgba(0, 0, 0, .12);
}

.tab-content {
  display: block;
  //display: flex;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  @media screen and (max-width: 600px) {
    overflow-y: visible;
  }
  //align-items: center;
  //justify-content: center;
  //overflow-y: scroll;
}

.appeal-title {
  font-size: 20px;
  line-height: 1.6;
  text-align: center;
}

.appeal-container {
  margin: 40px;
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.tab-pane.active {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 600px) {
    width: 100%;
  }
}

.appeal-rejection-button {
  display: flex;
  justify-content: center;
  margin-top: 24px;

}

.modal-title {
  width: 100%;
}

.modal-header-container {
  justify-content: space-between;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  width: 100%;
}

.modal-content, .modal-header {
  border-radius: 15px;

}

.modal-content {
  padding: 32px;
  border: 2px solid black;
  @media screen and (max-width: 400px) {
    padding: 10px;
  }
}

.modal-header {
  padding: 0;

  .header {
    color: black;
    font-size: 24px;
    font-weight: bold;
  }
}

.modal-body-container {
  display: flex;
  flex-direction: column;

  .modal-body-header {
    font-size: 16px;
    font-weight: 600;
  }
}


.modal-button {
  min-width: 0;
  padding: 5px 0;
}

.appeal-buttons {
  display: flex;
  justify-content: space-around;
  margin: 60px 0;
  @media screen and (max-width: 600px) {
    flex-wrap: nowrap;
    justify-content: space-between;

    & > * {
      min-width: auto;
    }
  }

  > * {

  }

  & > :first-child {
    width: 57%;
  }

  & > :last-child {
    width: 20%;
  }
}

.personal-area-container {
  display: flex;
  flex-direction: column;
  //height: 100%;
  @media screen and (max-width: 600px) {
    //margin-top: 150px;
  }
}

.personal-area-wrapper {
  display: flex;
  flex-direction: column;
  //height: 100%;
}

.appeal-of-typing-error-title {

  font-size: 20px;
  text-align: center;
  margin-bottom: 80px;
  padding-top: 24px;
  @media screen and (max-width: 600px) {
    padding-top: 16px;

  }
}

.appeal-of-typing-error {
  margin: 0 30%;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 600px) {
    margin: 0 25px;
  }
}

.nav-item {
  @media screen and (max-width: 600px) {
    flex: 1;
  }
}

.nav-tabs .nav-link {
  padding-top: 18px;
  @media screen and (max-width: 600px) {
    padding: 18px 0px;
  }

}

.appeal-details-container {
  margin-bottom: 40px;

  .input-wrapper {
    margin-top: 8px;
  }

  .appeal-details {
    font-size: 16px;
    font-weight: 600;
  }
}

.scholarship-status-container {
  margin-top: 80px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  @media screen and (max-width: 600px) {
    margin-top: 40px;
  }

  .scholarship-status-square-wrapper {
    border-radius: 32px;
    background-color: #00ff96;
    width: 30%;
    padding-bottom: 8px;
    @media screen and (max-width: 1200px) {
      width: 50%;
    }
    @media screen and (max-width: 900px) {
      width: 65%;
    }
    @media screen and (max-width: 750px) {
      width: 80%;
    }
    @media screen and (max-width: 600px) {
      width: 85%;
    }

  }

  .scholarship-status-square-container {
    display: flex;
    flex-wrap: wrap;
    border-radius: 32px;
    border: solid 2px #000;
    background-color: #fff;
    padding: 40px;
    @media screen and (max-width: 375px) {
      padding: 24px;
    }

    .scholarship-details {
      width: 50%;
      flex: 1;
      display: flex;
      flex-direction: column;

      .scholarship-title {
        font-size: 24px;
        font-weight: bold;
        line-height: 1.25;
      }

      .scholarship-time {
        margin-top: 8px;
        font-size: 20px;
        line-height: 1.6;
      }
    }

    .scholarship-status-wrapper {
      width: 50%;
      flex: 1;
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
      &>a{
        text-align: center;
      }
      @media screen and (max-width: 600px){
      flex-direction: column-reverse;
        //justify-content: center;
        align-items: center;
      }
      .scholarship-status {
        background: rgba(110, 25, 240, 0.12);
        border-radius: 20px;
        left: 0;
        padding: 7px 24px;
        text-align: center;
        @media screen and (max-width: 600px){
            margin-bottom: 24px;
        }
      }

      .print-button {
        margin-left: 16px;
        background: rgb(107 104 112 / 12%);
        border-radius: 20px;
        left: 0;
        padding: 7px 17px;
        @media screen and (max-width: 600px){
          text-align: center;
        }
      }
    }

    .action-button-wrapper{
     display: flex;
      width: 100%;
      justify-content: center;
    .action-button {
      margin-top: 40px;
      width: 35%;
    }
    }

  }
}

.appeal-input-details-container{
  &>:first-child{
    height: 168px;
    &>:first-child{
      height: 100%;
      width: 100%;
      resize: none;
      border: none;
      background: transparent;
    }
  }
}

.no-dots {
  list-style-type: none;
}

.no-margin {
  margin-right: 0px;
}

.request-data-item {
  align-content: center;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
}
