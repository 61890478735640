.document-item-container {
  display: flex;
  justify-content: space-between;
  align-content: center;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  margin: 16px 24px 16px 24px;

}

.documents-tab-container {
  width: 708px;
}

@media screen and (max-width: 600px) {
  .documents-tab-container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}
.document-item-icons {
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  cursor: pointer;
}