$brand-danger: 'red';
$black-01: #000;
$black-02: #333;
$black-03: #666;
$black-04: #999;
$black-05: #ccc;
$black-06: #e6e6e6;
$black-07: #f3f5f7;
$black-08: #fafafa;
$white: #fff;
$gray-dark: #949494;
$gray-light: #f5f5f5;
$accent-01: #d76e00;
$accent-02: #ff890a;
$accent-03: #ffa13d;
$accent-04: #ffba70;
$accent-05: #ffebd6;

$primary-01: #008000;
$primary-02: #00b300;
$primary-03: #00e600;
$primary-04: #80ff80;
$primary-05: #e6ffe6;

$secondary-01: #3a75c4;
$secondary-03: #0fa3ff;
$secondary-05: #a8deff;

$error-01: #c10020;
$error-02: #f50029;
$error-03: #ff5c77;
$error-04: #ffc2cc;
$error-05: #fff5f7;

$brand-primary: $primary-02;
$brand-warning: $accent-02;
$brand-danger: $error-02;

$body-bg: $white;

$text-reverse: $white;

$border-radius: 4px;
$input-border-radius: .8rem;

$base-focus: rgba(78, 163, 30, 0.4);

$shadow-level-5: 0 .2rem .7rem 0 rgba($black-01, 0.25);
$shadow-level-2-top: 0 -.2rem .7rem 0 rgba($black-01, 0.09);

.suggestion {
  &-wrapper {
    background-color: $white;
    box-shadow: 0 .6rem 1rem .2rem rgba($black-01, 0.09);;
    position: absolute;
    margin-top: 1px;
    top: 100%;
    width: 100%;
    z-index: 1;

    &.visible {
      visibility: visible;
    }

    &.pos-top {
      top: auto;
      bottom: 100%;
    }

    & .nodata {
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 200px;
      font-weight: lighter;
      font-family: "roboto", sans-serif;
      font-size: 16px;
      text-transform: uppercase;
      color: $black-03;
    }

    & .loader-wrapper {
      min-height: 200px;
    }
  }

  &-list {
    padding-left: 0;
    margin-bottom: 0;
    max-height: 200px;
    overflow-x: hidden;
    overflow-y: auto;
  }

  &-item {
    padding: 3px 10px 5px 10px;
    background-color: rgba(0, 0, 0, 0.04);
    height: 55px;
    border: none;
    cursor: pointer;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    color: #000;
    display: flex;
    flex-direction: row;
    align-items: center;

    &.placeholder {
    color: $black-03;
  }

  &.selected {
    background-color: $black-06;
    color: $black-01;

    &:hover {
      background-color: rgba(110, 25, 240, 0.08);
    }
  }

  &.highlighted {
    background-color: $black-06;

    &:hover {
      box-shadow: none;
    }
  }

  &:hover {
    background-color: rgba(110, 25, 240, 0.08);
  }
}

}

%suggestion {
  padding: 3px 10px 5px 10px;
  margin: 2px;
  border-radius: $border-radius;
  cursor: pointer;

  &:hover {
    background-color: $black-07;
  }
}

%suggestions-container {
  width: 0;
}

%suggestions-container-opened {
  background: $white;
  box-shadow: 0 .6rem 1rem .2rem rgba($black-01, 0.09);
  position: absolute;
  margin-top: 1px;
  top: 100%;
  width: 100%;
  z-index: 1;
}

%suggestions-container-list {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
  max-height: 200px;
  overflow-x: hidden;
  overflow-y: auto;
}

%suggestion-placeholder {
  color: $black-03;
}

%suggestion-selected {
  background-color: $black-06;
  color: $black-01;

  &:hover {
    background-color: $black-05;
  }
}

%suggestion-highlighted {
  background-color: $black-06;

  &:hover {
    box-shadow: none;
  }
}

%common-dropdown-icon {
  display: inline-block;
  font-family: "Font Awesome 5 Free";
  speak: none;
  font-size: 20px;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  vertical-align: top;
  color: $black-01;
}

%input {
  outline: none;
  color: $black-01;
  border: 1px solid $black-05;
  background-color: $white;
  padding: 5px 4px 7px 12px;
  border-radius: $border-radius;
  display: flex;
  justify-content: space-between;
  height: 32px;
  font-size: 14px;
}

%input-wrapper {
  color: $black-01;
  align-items: center;
  border: 1px solid $black-05;
  background-color: $white;
  border-radius: $border-radius;
  display: flex;
  justify-content: space-between;
  height: 32px;

  & > input[class*="input"] {
    background-color: transparent;
    border: none;
    outline: none;
    width: 100%;
    height: 30px;
    border-radius: $border-radius;
    font-family: inherit;
    font-size: 14px;
    padding: 0 4px 0 8px;
    min-width: 0;

    &::placeholder {
      @extend %input-placeholder;
    }
  }
}

%input-hovered {
  border-color: $black-02;
}

%input-focused {
  border-bottom: 1px solid $black-02;
  box-shadow: none;
}

%input-disabled {
  border-color: $black-05;
  background-color: $black-07 !important;
  color: $black-03 !important;
}

%input-placeholder {
  color: $black-03;
}

%input-prefix {
  margin-left: 8px;
}

%input-suffix {
  margin-right: 14px;
}

%input-prefix, %input-suffix {
  user-select: none;
  flex: none;
  white-space: nowrap;
  color: $black-03;
}

%input-icon-search {
  &:before {
    content: '\e92c';
    color: $black-03;
    width: 20px;
    height: 20px;
    display: block;
  }
}

%input-clear-icon {
  font-size: 18px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  color: $black-03;

  &::before {
    font-family: "Font Awesome 5 Free";
    content: "\00D7";
    position: absolute;
    top: 45%;
    right: 2px;
    transform: translate(-50%, -50%);
    font-style: normal;
  }
}

.dropdownselect {
  &-wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
  }

  &-input-wrapper {
    @extend %input-wrapper;
    padding: 0;
    align-items: center;

    &:not(.focused):not(.danger):not(.disabled):hover {
      @extend %input-hovered;
    }

    &:placeholder-shown {
      border-bottom: 1px solid black !important;
      padding-bottom: 2px;
      background-color: rgba(0, 0, 0, 0.04);
    }

    &:not(.disabled):not(.danger):not(.hover):has([value=""]) {
      border-bottom: 1px solid black;
      padding-bottom: 2px;
      background-color: rgba(0, 0, 0, 0.04);
    }

    &:not(.focused):not(.disabled).danger {
      border-color: $brand-danger;
    }

    &.focused {
      @extend %input-focused;
    }

    &.disabled {
      @extend %input-disabled;

      & .dropdownselect-select-icon {
        cursor: default;
      }
    }
  }

  &-input {
    border: none;
    width: 100%;
    padding: 5px 4px 7px 12px;
    text-overflow: ellipsis;
    background-color: rgba(0, 0, 0, 0);

    &::placeholder {
      color: $black-03;
    }
  }

  &-clear-icon {
    font-size: 18px;
    width: 30px;
    height: 30px;
    cursor: pointer;
    position: relative;

    &::before {
      content: "\00D7";
      color: black;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 20px;
      height: 20px;
      display: block;
    }
  }

  &-select-icon {
    margin: 16px;
    width: 30px;
    height: 29px;
    padding: 4px;
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
    transition: transform 0.2s linear;

    &.opened {
      transform: rotate(180deg);
    }

    &::before {
      content: ' ';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: block;
      content: "\f078";
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      color: black;
      font-size: 20px;
    }
  }

  &-filter {
    &-wrapper {
      @extend %input;
      @extend %input-focused;
      display: flex;
      justify-content: space-between;
      padding: 3px 4px 3px 12px;
      margin: 5px 2px;
    }

    &-input {
      border: none;
      width: 100%;
      font-size: 14px;
    }

    &-icon {
      @extend %common-dropdown-icon;
      @extend %input-icon-search;
      padding: 2px 5px 0 0;
      color: $black-03;
    }
  }

  &-suggestions {
    &-container {
      @extend %suggestions-container;

      &.opened {
        @extend %suggestions-container-opened;
      }
    }

    &-list {
      @extend %suggestions-container-list;
    }
  }

  &-suggestion {
    @extend %suggestion;

    &.placeholder {
      @extend %suggestion-placeholder;
    }

    &.selected {
      @extend %suggestion-selected;
    }
  }
}

// условие для дропа внутри списочной формы
ul[class*=list] {
  .dropdownselect {
    &-wrapper {
      ul {
        padding: 0;
        list-style-type: none;
      }
    }
  }
}


@mixin button-disabled($type) {
  background-color: $type !important;
  color: $text-reverse !important;
  border-color: $type !important;
}

.dropdown-wrapper {
  display: inline-block;
  position: relative;
  overflow: visible; // fix IE11 <button/> overflow: hidden

  & > ul {
    background-color: $white;
    border-radius: $input-border-radius;
    min-width: 100%;
    max-height: 200px;
    display: none;
    margin-top: 4px;
    padding: 4px 4px 0;
    position: absolute;
    top: 100%;
    border-bottom: 4px solid $white;
    box-shadow: $shadow-level-5;
    overflow-x: hidden;
    overflow-y: auto;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }

    scrollbar-width: none;
    visibility: hidden;

    &.visible {
      visibility: visible;
    }

    &.pos-top {
      top: auto;
      bottom: 100%;
      margin-bottom: 4px;
      margin-top: 0;
      box-shadow: $shadow-level-2-top;
    }

    &.pos-right {
      right: 0;
    }

    &:not(.pos-right) {
      left: 0;
    }

    & > li {
      display: block;
      position: relative;
      padding: 0;
      border-radius: 8px !important;

      &.level-2 {
        border-top: 0;

        &:last-child {
          border-bottom: 0;
        }
      }

      & > a:not(.button), & > div, & > span {
        min-width: 160px;
        margin-bottom: 2px;
        padding: 6px 8px;
        color: $black-01;
        display: flex;
        justify-content: start;
        border-radius: 8px !important;
      }

      &:last-child > a:not(.button), &:last-child > div, &:last-child > span {
        margin-bottom: 0;
      }

      &:hover > a:not(.count), &:hover > div:not(.count), &:hover > span:not(.count) {
        background-color: $black-07;
      }

      &.active > a, &.active > div, &.active > span {
        background-color: $black-05;
      }

      .count {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        min-width: 8px;
        width: 8px;
        height: 8px;
        background-color: $accent-02;
        border-radius: 50%;
        padding: 0;
      }

      & > a:focus {
        box-shadow: none;
      }

    }
  }

  &.opened {
    z-index: 999;

    & > ul {
      display: block;
    }
  }

  &.disabled {
    background-color: transparent !important;

    .button-wrapper {
      color: $black-05 !important;
      background-color: $body-bg !important;
      border-color: $black-05;

      > * {
        color: $black-05 !important;
      }

      &:not(.blank):focus {
        padding: 7px 40px 7px 24px;
        border-width: 1px;
        border-color: $black-05;

        &::after {
          right: 17px;
        }
      }

      &.small:not(.blank):focus {
        padding: 5px 31px 5px 16px;

        &::after {
          right: 11px;
        }
      }

      &.more {
        &:not(.blank):focus {
          padding: 5px;
        }

        &.small:not(.blank):focus {
          padding: 3px;
        }
      }

      &.success,
      &.primary {
        &:hover, & {
          @include button-disabled($primary-04);
        }
      }

      &.warning {
        &:hover, & {
          @include button-disabled($accent-04);
        }
      }

      &.danger {
        &:hover, & {
          @include button-disabled($error-04);
        }
      }
    }
  }
}

%suggestion {
  padding: 3px 10px 5px 10px;
  margin: 2px;
  border: none;
  border-radius: $border-radius;
  cursor: pointer;

  &:hover {
    background-color: $black-07;
  }
}

%suggestions-container {
  width: 0;
}

%suggestions-container-opened {
  background: $white;
  border: none;
  position: absolute;
  margin-top: 1px;
  top: 100%;
  width: 100%;
  z-index: 1;
}

%suggestions-container-list {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
  max-height: 200px;
  overflow-x: hidden;
  overflow-y: auto;
  border: none;
}

%suggestion-placeholder {
  color: $black-03;
}

%suggestion-selected {
  background-color: #6e19f0;
  color: $black-01;

  &:hover {
    background-color: #6e19f0;
  }
}

%suggestion-highlighted {
  background-color: #6e19f0;

  &:hover {
    box-shadow: none;
  }
}

.autocomplete {
  &-wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
  }

  &-input-wrapper {
    @extend %input-wrapper;
    padding: 0;
    align-items: center;

    &:not(.focused):not(.danger):not(.disabled):hover {
      @extend %input-hovered;
    }

    &:not(.focused):not(.disabled).danger {
      border-color: $brand-danger;
    }

    &.focused {
      @extend %input-focused;
    }

    &.disabled {
      @extend %input-disabled;

      & .dropdownselect-select-icon {
        cursor: default;
      }
    }
  }

  &-input {
    border: none;
    width: 100%;
    padding: 5px 4px 7px 12px;
    text-overflow: ellipsis;
    background-color: rgba(0, 0, 0, 0);

    &::placeholder {
      color: $black-03;
    }
  }

  &-clear-icon {
    font-size: 18px;
    width: 30px;
    height: 30px;
    cursor: pointer;
    position: relative;
    font-style: normal;

    &::before {
      content: "\00D7";
      font-family: "Font Awesome 5 Free";
      color: $black-03;
      position: absolute;
      top: 43%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: block;
    }
  }

  &-suggestions-container {
    @extend %suggestions-container;

    &.opened {
      @extend %suggestions-container-opened;
    }
  }

  &-suggestions-list {
    @extend %suggestions-container-list;
  }

  &-suggestion {
    @extend %suggestion;

    &.highlighted {
      @extend %suggestion-highlighted;
    }
  }
}

// условие для дропа внутри списочной формы
ul[class*=list] {
  .dropdownselect {
    &-wrapper {
      ul {
        padding: 0;
        list-style-type: none;
      }
    }
  }
}


//backgroundColor: 'rgba(0, 0, 0, 0.04)', height: '55px', border: 'none'
