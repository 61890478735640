@import './src/assets/scss/variables/colors';

.custom-button {
  cursor: pointer;
  height: 42px;
  font-size: 14px;

  .button-icon {
    margin-right: 10px;
  }
}

.btn.btn-notification,
.btn-notification {
  background: $color-logout;
  color: white;

  :hover {
    color: white;
    box-shadow: 1px 2px 5px $color-logout;
  }
}

.btn-grey-light {
  background: $grey-light;
}

.btn-primary-perach {
  height: 48px;
  border-radius: 24px;
  background-color: #6e19f0;

  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #fff;

  &:hover {
    background-color: #5e14cc;
    color: #fff;
  }

  &:active {
    background-color: #5e14cc;
    color: #fff;
  }

  &:focus {
    border: solid 2px #000;
    background-color: #6e19f0;
    color: #fff;
  }
}

.btn-primary-perach.btn-disabled-perach {
  background-color: #e0e0e0;
  color: #000;

  height: 48px;
  border: none;
  border-radius: 24px;

  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
}

.btn-info-perach {
  height: 48px;
  border-radius: 24px;
  border: solid 2px #000;
  background-color: #FFFFFF;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #000;

  &:hover {
    background-color: rgba(110, 25, 240, 0.12);
  }

  &:active {
    background-color: rgba(110, 25, 240, 0.24);
  }
}

.btn-info-perach.btn-disabled-perach {
  border: solid 2px #e0e0e0;
  color: #999999;
  background-color: #fff;

  height: 48px;
  border-radius: 24px;

  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
}


.btn-blank-perach {
  height: 48px;
  border-radius: 24px;
  color: #000;

  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;

  &:hover, &:active, &:focus {
    border: solid 2px #000;
  }
}

.btn-blank-perach.btn-disabled-perach {
  border: none;
  color: #999999;

  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
}


@mixin button-default($type) {
  background-color: $type;
  color: $white;
  border-color: transparent;
}

@mixin button-disabled($type) {
  background-color: $type !important;
  background-image: none;
  border-color: $type;
  color: $black-01 !important;
}

@keyframes cog {
  from {
    transform:rotate(0deg);
  }
  to {
    transform:rotate(360deg);
  }
}

$border-radius: 24px;
$disabled-primary-color: #e0e0e0;

.button-wrapper {
  border-radius: $border-radius;
  background-color: $white;
  text-align: center;
  cursor: pointer;
  padding: 5px 19px;
  min-width: 120px;
  color: inherit;
  //border: 1px solid $black-05;
  outline: none !important;

  &.small {
    padding: 3px 15px;
    min-width: 100px;
  }

  &.large {
    padding: 9px 23px;
    min-width: 150px;
  }

  &.block {
    display: block;
    width: 100%;
  }

  &.blank {
    color: #369 !important;
    background: transparent !important;
    border-color: transparent !important;
  }

  &.active {
    background-color: $black-06;
    border-color: $black-04;
  }

  &.success, &.primary {
    @include button-default($primary-02);
  }

  &.secondary {
    @include button-default($secondary-03);
  }

  &.danger {
    @include button-default($error-02);
  }

  &.warning {
    @include button-default($accent-02);
  }

  &:not(.success):not(.warning):not(.danger):not(.primary):not(.secondary):not(.blank):not(.border):not(.disabled):not(.button-group-item) {
    &:hover, &:focus {
      border-color: $black-03;
    }

    &.active:hover {
      background-color: $black-05;
      border-color: $black-03;
    }
  }

  &.disabled {
    color: $black-01;
    background-color: #e0e0e0;
    cursor: not-allowed;
    border-color: $black-05;

    &.success, &.primary, &.success:hover, &.primary:hover {
      @include button-disabled(#e0e0e0);
    }

    &.warning, &.warning:hover {
      @include button-disabled(#e0e0e0);
    }

    &.danger, &.danger:hover {
      @include button-disabled(#e0e0e0);
    }

    &:hover {
      @include button-disabled(#e0e0e0);
    }
    &:active {
      @include button-disabled(#e0e0e0);
    }
    &:focus {
      @include button-disabled(#e0e0e0);
    }
  }

  &.border {
    color: $black-01;
    background-color: transparent;
    border-color: $black-01 !important;

    &:hover, &:focus {
      background-color: $black-05;
      border-color: $black-03 !important;
    }
  }

  &.loading {
    position: relative;
    color: transparent !important;
    opacity: 1;

    &::before {
      content: '\f110';
      position: absolute;
      left: 50%;
      margin-left: -1rem;
      width: 2rem;
      height: 2rem;
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      color: $black-05;
      display: block;
      animation: cog 3s linear infinite;
    }

    &.success::before, &.danger::before, &.warning::before, &.primary::before, &.secondary::before {
      color: $white;
    }
  }
}
