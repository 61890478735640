@import './src/assets/scss/variables/colors';

.main-menu-content .navigation-main .nav-item.active a span {
  color: white;
}

.vertical-layout.vertical-menu-modern .main-menu .navigation li.active svg {
  color: white;
}

.vertical-layout.vertical-menu-modern .main-menu .navigation li a i:before {
  color: unset;
}

.vertical-layout.vertical-menu-modern.menu-collapsed
  .main-menu:not(.expanded)
  .navigation
  li.active
  a {
  background: $primary;
}
