.loader {
  &-wrapper {
    position: relative;
    min-height: 100px;

    &.small,
    &.small .loader-container {
      min-height: 33px;
    }

    &.fullscreen {
      background-color: rgba($white, 0.8);
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 10100;
    }
  }

  &-container {
    background-color: rgba($white, 0.8);
    min-height: 100px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
  }

  &-element {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -30px;
    margin-top: -30px;
    animation: r-to-left 2s linear infinite;
    transform-origin: 50%;
    background: linear-gradient(200deg, rgba($secondary-01,1) 0%, rgba($white,0) 100%);
    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      right: 0;
      margin-left: -4.5px;
      border-radius: 0 100% 100% 0 / 0 50% 50% 0;
      width: 30px;
      height: 60px;
      background: linear-gradient(180deg, rgba($white,0) 0%, rgba($secondary-01,1) 100%);
    }
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -25px;
      margin-top: -25px;
      border-radius: 50%;
      width: 50px;
      height: 50px;
      background: $white;
    }
    @keyframes r-to-left {
      0% {
        transform: rotate(0deg);
      }
      50% {
        transform: rotate(180deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }

  &-logo {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -12px;
    margin-top: -12px;
    width: 24px;
    height: 24px;
    z-index: 1000;
    transform-origin: 50%;
    background: none;
    background-position: center;
    background-size: 100% 100%;
  }

  &-spinner {
    width: 56px;
    height: 56px;
    background: none;

    &::before, &::after {
      content: '';
      display: block;
      position: absolute;
      border-radius: 50%;
      border: 1px $black-01 solid;
      border-top-color: transparent;
      border-bottom-color: transparent;
      background-color: transparent;
    }

    &::before {
      width: 100%;
      height: 100%;
      animation: loader-spinner-animation .7s linear infinite;
      background: none;
    }

    &::after {
      width: 50%;
      height: 50%;
      top: 25%;
      left: 25%;
      margin-left: 0;
      margin-top: 0;
      animation: loader-spinner-animation reverse 1.4s linear infinite;
    }
  }

  @keyframes loader-spinner-animation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

}

.global-loader-overflow {
  overflow: hidden;
}
