$color-text-main: #444b4d;
$color-text-second: rgba(68, 75, 77, 0.3);
$color-logout: #e14069;
$color-logout-light: rgba(225, 64, 105, 0.24);
$grey-light: #f8f8f8;
$color-border-digit: #d8d6de;
$color-square: #d8d8d8;

$color-icon: #4b4b4b;
$color-icon-light: rgba(29.41, 29.41, 29.41, 0.05);

$primary: #6e19f0;
$primary-light: rgba(2, 130, 255, 0.24);
$color-placeholder-text: #b9b9c3;
$color-delete-modal: #ea5455;
