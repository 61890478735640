.password {
  &-wrapper {
    display: flex;
    flex-direction: column;
  }

  &-element-wrapper {
    @extend %input-wrapper;
    padding: 0;
    position: relative;
    display: flex;
    flex-direction: row;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 2px solid black;
    border-radius: 0;
    background-color: rgba(0, 0, 0, 0.04);
    outline: none;
    padding-bottom: 1px;
    width: 100%;
    height: 56px;


    &:not(.disabled):not(.danger):hover:not(.focused) {
      @extend %input-hovered;
    }

    &:not(.focused):not(.disabled).danger {
      border-color: #e6175c;
    }

    &:not(.disabled):not(.danger):not(.hover):has([value=""]) {
      border-bottom: 1px solid black;
      padding-bottom: 2px;
      background-color: rgba(0, 0, 0, 0.04);
    }

    &.focused {
      @extend %input-focused;
    }

    &.disabled {
      @extend %input-disabled;
    }
  }

  &-clear-icon {
    font-size: 14px;
    width: 30px;
    height: 30px;
    cursor: pointer;
    font-family: "Font Awesome 5 Free";
    color: $black-03;
    position: absolute;
    right: 30px;
    font-size: 2em;

    &::before {
      content: "\00D7";
      position: absolute;
      top: 50%;
      right: 2px;
      transform: translate(-50%, -50%);
      font-style: normal;
    }
  }

  &-icon {
    margin: 0;
    font-size: 14px;
    color: $black-03;

    &:before {
      position: relative;
      top: 5px;
    }

    &.icon-left {
      padding-left: 5px;

      & + input {
        padding-left: 7px;
      }
    }

    &.icon-right {
      padding-right: 7px;
    }
  }

  &-prefix {
    @extend %input-prefix;
  }

  &-suffix {
    @extend %input-suffix;
  }

  &-is-visible,
  &-is-hidden {
    height: 20px;
    cursor: pointer;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    &::before {
      color: black;
      margin: 0 16px 0 4px;
      font-size: 20px;
      font-style: normal;
      font-family: "Font Awesome 5 Free" !important;
    }
  }

  &-is-hidden {
    &::before {
      content: "\f070";
      color: black;
      left: 0;
      margin-left: 16px;
    }
  }

  &-is-visible {
    &::before {
      content: "\f06e";
      color: black;
      left: 0;
      margin-left: 16px;
    }
  }

  &-message {
    &-default,
    &-weak,
    &-medium,
    &-strong {
      font-size: 12px;
    }
    &-default {
      color: $gray-dark;
    }
    &-weak {
      color: $accent-02;
    }
    &-medium,
    &-strong {
      color: $primary-02;
    }
  }

  &-input {
    padding-right: 36px !important;

    &-clearable {
      padding-right: 60px !important;
    }

    &::-ms {
      &-clear,
      &-reveal {
        display: none;
      }
    }
  }
}

