ul, ol {
  list-style-position: outside;
}

.list,
.list-item {
  margin-bottom: 1rem;
}

ul.list ol,
ol.list ol,
ol.list-item {
  list-style-type: decimal;
}

ul.list-item {
  list-style-type: disc;
}

.list-item ol {
  list-style-type: lower-alpha;
}

ul.list:not(.list-form) ul,
.list-item ul {
  list-style-type: circle;
}

.list > dt,
.list > dd,
.list > li,
.list-item > li,
.list-check > li,
.list-tree > li,
.list-h > li {
  padding-top: .3rem;
}

.list > dd,
.list > li,
.list-item > li,
.list-check > li,
.list-tree > li,
.list-h > li {
  padding-bottom: .3rem;
}

.list-space-large li {
  margin-bottom: .4rem;
}

.list-item {
  padding-left: 3rem;
}

.list-check {
  padding-left: 2.5rem;
}

ul.list ul:not(.k-reset),
ul.list ol:not(.k-reset),
.list-item ul:not(.k-reset),
.list-item ol:not(.k-reset) {
  padding: .3rem 0 0 2rem;
}

.list-check > li {
  position: relative;
}

.list-check > li:before {
  color: #4ea31e;
  content: "\e96c";
  display: block;
  font-family: "novicon" !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  position: absolute;
  left: -2.5rem;
  top: .3rem;
  speak: none;
  text-transform: none;
  line-height: 1;
  font-size: 20px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  vertical-align: middle;
}

.list-check > li.del {
  opacity: .3;
}

.list-check > li.del:before {
  display: none;
}

.list-check > li.fail:before {
  color: #e84040;
  content: "\e988";
}

.list-check > li.nocheck:before {
  display: none;
}

.list-h > li {
  display: inline-block;
  margin-right: 1rem;
}

.list-h > li.dropdown li {
  display: block;
  margin-right: 0;
}

.list-h > li:last-child {
  margin-right: 0;
}

.list-tree li li {
  padding-left: 1.5rem;
  position: relative;
  padding-bottom: .2rem;
}

.list-tree li li:after,
.list-tree li li:before {
  border-color: #949494;
  border-style: solid;
  content: " ";
  display: block;
  position: absolute;
  top: 0;
  left: .5rem;
  width: .5rem;
}

.list-tree li li:before {
  height: 1rem;
  border-width: 0 0 1px 1px;
}

.list-tree li li:after {
  bottom: 0;
  border-width: 0 0 0 1px;
}

.list-tree li li:last-child:after {
  display: none;
}

/*
	.form > .hover,
	.form > .focus,
	.form > li:hover,
	.form > dd:hover,
	.form   dt:hover + dd {
		color: #000;
		background:            floralwhite;
		box-shadow: -.3rem 0 0 floralwhite, .3rem 0 0 floralwhite;
	}

	[class*="list"] > .hover,
	[class*="list"] > .focus,
	[class*="list"] > li:hover,
	[class*="list"] > dd:hover,
	[class*="list"]   dt:hover + dd {
		color: #000;
		background:            floralwhite;
		box-shadow: -.3rem 0 0 floralwhite, .3rem 0 0 floralwhite;
	}

[class*="list"] label.block {color: #949495;}
*/
[class*="list"] dt {
  float: left;
}

.w-10[class*="list"] dt {
  width: 9%;
}

.w-15[class*="list"] dt {
  width: 14%;
}

.w-20[class*="list"] dt {
  width: 19%;
}

.w-25[class*="list"] dt {
  width: 24%;
}

.w-30[class*="list"] dt {
  width: 29%;
}

.w-35[class*="list"] dt {
  width: 34%;
}

.w-40[class*="list"] dt {
  width: 39%;
}

.w-10[class*="list"] dd {
  padding-left: 10%;
}

.w-15[class*="list"] dd {
  padding-left: 15%;
}

.w-20[class*="list"] dd {
  padding-left: 20%;
}

.w-25[class*="list"] dd {
  padding-left: 25%;
}

.w-30[class*="list"] dd {
  padding-left: 30%;
}

.w-35[class*="list"] dd {
  padding-left: 35%;
}

.w-40[class*="list"] dd {
  padding-left: 40%;
}

[class*="list"] dd:after {
  content: " ";
  display: block;
  float: none !important;
  clear: both;
  height: 0;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.list.nowrap > dd {
  padding-left: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

[class*="list"] dd {
  min-height: 2rem;
}

.form[class*="list"] dd {
  min-height: 3.2rem;
}

.form[class*="list"] dt > label {
  margin-top: .6rem;
  display: block;
}

.form[class*="list"] dd > .list {
  margin-top: .2rem;
}

/*
.form[class*="list"] label,
.form[class*="list"] .k-widget,
.form[class*="list"] .k-textbox {vertical-align: top;}*/
[class*="list"].col-2 {
  -webkit-column-count: 2;
  -moz-column-count: 2;
  -o-column-count: 2;
  column-count: 2;
}

[class*="list"].col-3 {
  -webkit-column-count: 3;
  -moz-column-count: 3;
  -o-column-count: 3;
  column-count: 3;
}

[class*="list"].col-4 {
  -webkit-column-count: 4;
  -moz-column-count: 4;
  -o-column-count: 4;
  column-count: 4;
}

.tree-container {
  position: relative;
}

.tree-container .parent-icon {
  position: relative;
  left: .3rem;
  border: 0;
}

.tree-container > ul {
  position: relative;
}

.tree-container > ul::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 1.2rem;
  width: 1.6rem;
  height: .1rem;
  background: #d0d7dd;
}

.tree-container > ul:last-child::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 1.1rem;
  background: #fff;
  width: .2rem;
  height: 50%;
  z-index: 100;
}

.tree-container span {
  display: block;
  border-left: 0.1rem solid #d0d7dd;
}

.tree-container a {
  display: block;
  padding: 1rem 0 1rem 4rem;
  position: relative;
  background-color: #fff;
}

.tree-container a::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  width: 1.4rem;
  height: .1rem;
  background: #d0d7dd;
  z-index: 101;
}

.tree-container a i {
  position: absolute;
  left: 1.4rem;
  top: 50%;
  margin-top: -1rem;
}

.tree-container ul li {
  position: relative;
  margin: 0 0 0 1.2rem;
  padding-left: 1.4rem;
  border-left: 0.1rem solid #d0d7dd;
}

.tree-container ul li:first-child::after {
  content: '';
  position: absolute;
  width: .2rem;
  left: 1.4rem;
  top: 0;
  height: 50%;
  background: #fff;
}

.tree-container ul li:last-child::before {
  content: '';
  position: absolute;
  width: .2rem;
  left: 1.4rem;
  top: calc(50% + .1rem);
  height: 50%;
  background: #fff;
}


