.checkbox-label {
  margin: 8px;
  font-family: Assistant;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  //text-align: left;
  color: #000;
}

.cbx {
  margin: auto;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
}

.cbx span {
  display: inline-block;
  vertical-align: middle;
  transform: translate3d(0, 0, 0);
}

.cbx span:first-child {
  position: relative;
  width: 18px;
  height: 18px;
  border-radius: 0;
  transform: scale(1);
  vertical-align: middle;
  border: 1px solid black;
  transition: all 0.2s ease;
}

.cbx span:first-child svg {
  position: absolute;
  top: 3px;
  left: 2px;
  fill: none;
  stroke: black;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 16px;
  stroke-dashoffset: 16px;
  transition: all 0.3s ease;
  transition-delay: 0.1s;
  transform: translate3d(0, 0, 0);
}

.cbx span:first-child:before {
  content: "";
  width: 100%;
  height: 100%;
  background: rgba(0, 255, 150, 0.12);
  display: block;
  transform: scale(0);
  opacity: 1;
  border-radius: 50%;
}

.cbx span:last-child {
  padding-left: 8px;
}

.cbx:hover span:first-child {
  border-color: black;
}

.inp-cbx:checked + .cbx span:first-child {
  background: rgba(0, 255, 150, 0.12);
  border-color: black;
  animation: wave 0.4s ease;
}

.inp-cbx:checked + .cbx span:first-child svg {
  stroke-dashoffset: 0;
}

.inp-cbx:checked + .cbx span:first-child:before {
  transform: scale(3.5);
  opacity: 0;
  transition: all 0.6s ease;
}

.checkbox-wrapper {
  display: inline-flex;
}

@media screen and (max-width: 600px) {
  .cbx {
    margin: 10px auto auto auto;
    -webkit-user-select: none;
    user-select: none;
    cursor: pointer;
  }
}
