.option-box {
  display: flex;
  height: 56px;
  border-spacing: 0;
  border: 1px solid black;
}

.option-element {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex: 1;
  text-align: center;
  border-right: 1px solid black;

  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #000;
}

.option-element:first-child {
  border-right:none !important;
}
.selected-option {
  background-color: rgba(0, 255, 150, 0.12);
}

.optional-input-disabled {
  background-color: rgba(0, 0, 0, 0.1) !important;
  cursor: not-allowed;
  font-weight: bold;
}

.option-box-disabled {
  display: flex;
  height: 56px;
  border-spacing: 0;
  border: 1px solid black;
  cursor: not-allowed;
}
