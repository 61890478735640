.aw {
  width: auto;
}

.w-50 {
  width: 50px;
}

.w-100 {
  width: 100%;
}

.mb-8 {
  margin-bottom: 8px;
}

.mr-8 {
  margin-right: 8px;
}

.ml-8 {
  margin-left: 8px;
}

.mr-12 {
  margin-right: 12px;
}

.ml-12 {
  margin-left: 12px;
}

.ml-16 {
  margin-left: 16px;
}

.mr-16 {
  margin-right: 16px;
}

.mb-16 {
  margin-bottom: 16px;
}

.fs-20 {
  font-size: 20px;
}

.mt-34 {
  margin-top: 34px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mt-40 {
  margin-top: 40px;
}

.mb-80 {
  margin-bottom: 80px;
}

.mt-24 {
  margin-top: 24px;
}

.ml-24 {
  margin-left: 24px;
}

mr-24 {
  margin-right: 24px;
}

.ph-25 {
  padding-left: 25px;
  padding-right: 25px;
}

.ptb-30 {
  padding-bottom: 30px;
  padding-top: 30px;
}

.ptb-60 {
  padding-bottom: 60px;
  padding-top: 60px;
}

.pl-0 {
  padding-left: 0;
}

@media screen and (max-width: 600px) {
  .no-mobile {
    display: none !important;
  }
  .no-min-width-mob {
    min-width: 0 !important;
  }
}

.hidden {
  display: none;
}

.left {
  text-align: left;
}

.txt-danger {
  color: #e6175c !important;
}

.txt-bold {
  font-weight: bold;
}

.p-32 {
  padding: 32px;
}

.mt-24 {
  margin-top: 32px;
}

.pointer {
  cursor: pointer;
}

.fs-28 {
  font-size: 28px;
}

.fs-17 {
  font-size: 17px;
}

.no-wrap {
  white-space: nowrap;
}

.max-100 {
  max-width: 100%;
  max-height: 100%;
}

.ms-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.mob-16 {
  margin: 0 0 16px !important;
}
