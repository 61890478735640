@import '../../../../assets/scss/variables/colors';

.custom-button {
  cursor: pointer;
  height: 42px;
  font-size: 14px;

  .button-icon {
    margin-right: 10px;
  }
}

.btn.btn-notification,
.btn-notification {
  background: $color-logout;
  color: white;

  :hover {
    color: white;
    box-shadow: 1px 2px 5px $color-logout;
  }
}

.btn-grey-light {
  background: $grey-light;
}

.btn-primary-perach {
  height: 48px;
  border-radius: 24px;
  background-color: #6e19f0;

  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #fff;

  &:hover {
    background-color: #5e14cc;
    color: #fff;
  }

  &:active {
    background-color: #5e14cc;
    color: #fff;
  }

  &:focus {
    border: solid 2px #000;
    background-color: #6e19f0;
    color: #fff;
  }
}

.btn-primary-perach.btn-disabled-perach {
  background-color: #e0e0e0;
  color: #000;

  height: 48px;
  border: none;
  border-radius: 24px;

  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  }

.btn-info-perach {
  height: 48px;
  border-radius: 24px;
  border: solid 2px #000;

  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #000;

  &:hover {
    background-color: rgba(110, 25, 240, 0.12);
  }

  &:active {
    background-color: rgba(110, 25, 240, 0.24);
  }
}

.btn-info-perach.btn-disabled-perach {
  border: solid 2px #e0e0e0;
  color: #999999;
  background-color: #fff;

  height: 48px;
  border-radius: 24px;

  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
}


.btn-blank-perach {
  height: 48px;
  border-radius: 24px;
  color: #000;

  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;

  &:hover, &:active, &:focus {
    border: solid 2px #000;
  }
}

.btn-blank-perach.btn-disabled-perach {
  border: none;
  color: #999999;

  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
}
